$(document).ready(function(){
	'use strict';
	if(!document.querySelector('.right-floating-bar')) return false;

	// hoisting
	var floatingbar = {};
		
	floatingbar = function(){
		var $this = $(".right-floating-bar"),
			// $slider = $this.find(".rolling-area"),
			// $dimedLayer = $this.find(".back-layer-by-mobile"),
			// $list = $this.find(".bar-menu-list"),
			// $counter = $this.find(".status-bundle-location"),
			p =  floatingbar.prototype;
	
		p.init = function(){
			this.preset();
		// 	// this.activeViewed();
			
		// 	mql.minMd.addListener(p.resizeRFB);
		// 	this.resizeRFB(mql.minMd);
		};

		p.eventOn = function(){
			
			$this.on("mouseleave blur", function(e){
				if(!e.relatedTarget) return false;
				// p.fold();
			});

			// $list.on("mouseenter focus", function(){
			// 	p.expand();
			// 	p.repaintViewed();
			// });

			// $list.on("focus", ".recently-viewed .head-inner", function(){
			// 	p.expand();
			// 	p.repaintViewed();
			// });

			/* LGEUS-683 : 20200602 modify add */
			// $list.find("li:first-child a").on({
			// 	focusout: p.fold
			// });
			/* LGEUS-683 : LGEUS-683 modify */
			
			$this.find(".back-to-top").on({
				focus: p.fold
			});


			$this.find(".back-to-top").on("click touch", function(event){
				event.preventDefault();
				p.scrollTopToZero("html", 600);
			});

			// $this.find(".call-bar-menu-list").on("touch click", function(event){
			// 	event.preventDefault();
			// 	if( $this.hasClass("active") ){
			// 		p.listClose();
			// 		$this.find(".call-bar-menu-list").attr('aria-expanded', false)
			// 	}else{
			// 		p.listOpen();
			// 		$this.find(".call-bar-menu-list").attr('aria-expanded', true)
			// 	}
			// });
			// $this.find(".call-bar-menu-list").on("blur", function(event){
			// 	var $focusTarget = $list.find('a').eq(0);
			// 	setTimeout(function(){
		    //         $focusTarget.focus();
		    //     }, 50);
			// });

			// $dimedLayer.on("click", function(){
			// 	p.listClose();
			// });

			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				// fixed bar :: desktop only
				if(scroll > (416-110))  {
					$this.addClass("fixed");
				} else {
					$this.removeClass("fixed");
				}
				// fold bar :: desktop only
				// if( $list.attr("class").indexOf("fold") > 0 ){
				// 	$list.addClass("fold");
				// }
			});
		};

		p.preset = function(){
			// for mobile
			if($(window).width() <= 992) {
				p.eventOn();
			
			// for desktop
			} else {
				setTimeout(function() {
					// $list.removeClass('fold');
					setTimeout(function() {
						// $list.addClass('fold');
						p.eventOn();
					}, 1000);
				}, 300);
			}
		};

		// p.resizeRFB = function(e) {
		// 	if (e.matches) {
		// 		// reset desktop layout
		// 		$list.addClass("fold");
		// 	} else {
		// 		// clear mobile process
		// 		p.listClose();
		// 		$this.find(".recently-viewed").addClass("to-egg");
		// 	}
		// };

		p.scrollTopToZero = function(tg, motionSpeed){
			$(tg).stop().animate({
				scrollTop : 0
			}, motionSpeed);
		};

		// p.expand = function(){
		// 	$list.removeClass("fold").find(".recently-viewed").removeClass("to-egg");
		// };

		// p.fold = function(){
		// 	$list.addClass("fold").find(".recently-viewed").addClass("to-egg");
		// };

		// p.listClose = function(){
		// 	$this.removeClass("active");
		// 	$dimedLayer.removeClass("active");
		// 	$("body").removeClass("band-scroll");
		// };
		
		// p.listOpen = function(){
		// 	$this.addClass("active");
		// 	$dimedLayer.addClass("active");
		// 	$("body").addClass("band-scroll");
		// };

		// p.repaintViewed = function(){
		// 	if( $slider.hasClass("slick-slider") ){
		// 		$slider.slick('setPosition');
		// 	}
		// };

		// p.activeViewed = function(){
		// 	var countOutputElem = "<em class=\"stand\">1</em>/<span class=\"all-bundles\"></span>",
		// 		sliderBundle = "<div class=\"viewed-bundle\"></div>",
		// 		sliderItem = $slider.find(".viewed-item"),
		// 		sliderItemLength = $slider.find(".viewed-item").length,
		// 		pageCount = 0,
		// 		cutbundle = {};

		// 	// repaint DOM tree in slider. 
		// 	for(var i=0; i<sliderItemLength/3; i++){
		// 		var cutbundle = sliderItem.slice( (i*3), (i*3)+3 );
		// 		$slider.append(sliderBundle);
		// 		$slider.find(".viewed-bundle").eq(i).html(cutbundle);
		// 	}

		// 	// viewed count
		// 	pageCount = $slider.find(".viewed-bundle").length;
		// 	$counter.append(countOutputElem);
		// 	$this.find(".count-viewed").text(sliderItemLength);
		// 	$this.find(".all-bundles").text(pageCount);
		// 	if(pageCount === 0 || pageCount === undefined || pageCount === null) {
		// 		$counter.find(".stand").text("0");
		// 	}
			
		// 	// slider viewed list
		// 	if( sliderItemLength === 0 || sliderItemLength === undefined || sliderItemLength === null ){
		// 		$slider.siblings(".not-yet-view").addClass("active");
		// 		$slider.addClass("no-content");
		// 	}else{
		// 		$slider.slick({
		// 			accessibility : true,
		// 			arrows : true,
		// 			touchMove : false,
		// 			appendArrows : $this.find(".viewed-bundle-handler").find(".quiver")
		// 		});
		// 		$slider.on("afterChange", function(event, slick, currentSlide){
		// 			$counter.children(".stand").text(currentSlide + 1);
		// 		}); 
		// 	}
		// };
		p.init();
	};
	
	floatingbar();
});
